<template>
  <v-app>
    <div>

      <b-form @submit.stop.prevent="formOnsubmit">

        <!-- Input Name -->
        <b-form-group
          id="input-group-name"
          label="Nama Pendonor:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama Pendonor"
          >
          </b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <!-- Input Email -->
        <b-form-group
          id="input-group-email"
          label="Email Pendonor:"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="form.email"
            placeholder="Email Pendonor"
          >
          </b-form-input>
          <small class="text-danger">{{ error.email }}</small>
        </b-form-group>

        <!-- Input No KTP -->
        <b-form-group
          id="input-group-id-card-number"
          label="No KTP Pendonor:"
          label-for="input-id-card-number"
        >
          <b-form-input
            id="input-id-card-number"
            v-model="form.id_card_number"
            placeholder="No KTP Pendonor"
          >
          </b-form-input>
          <small class="text-danger">{{ error.id_card_number }}</small>
        </b-form-group>

        <!-- Input BloodType -->
        <b-form-group
          id="input-group-blood-type"
          label="Golongan Darah:"
          label-for="input-blood-type"
        >
          <treeselect
            v-model="form.blood_type_id"
            :multiple="false"
            :options="blood_types"
          />
          <small class="text-danger">{{ error.blood_type }}</small>
        </b-form-group>

        <!-- Input Age -->
        <!-- <b-form-group
        id="input-group-age"
        label="Umur Pendonor:"
        label-for="input-age"
      >
        <b-form-input
          id="input-age"
          v-model="form.age"
          type="number"
          placeholder="Umur Pendonor"
        >
        </b-form-input>
        <small class="text-danger">{{ error.age }}</small>
      </b-form-group> -->

        <!-- Input Birt Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal Lahir:"
          label-for="input-date"
        >

          <v-dialog
            ref="dialog"
            v-model="modal_birt_date"
            :return-value.sync="form.birt_date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">

              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-birt-date"
                v-model="form.birt_date"
              >
              </b-form-input>
            </template>

            <v-date-picker
              locale="id"
              v-if="modal_birt_date"
              v-model="form.birt_date"
              @input="setAge"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal_birt_date = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.birt_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input Last Donation Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal Terakhir Donor:"
          label-for="input-date"
        >

          <v-dialog
            ref="dialog_donation"
            v-model="modal_last_donation_date"
            :return-value.sync="form.last_donation_date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">

              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-last-donation-date"
                v-model="form.last_donation_date"
              >
              </b-form-input>
            </template>

            <v-date-picker
              locale="id"
              v-if="modal_last_donation_date"
              v-model="form.last_donation_date"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal_last_donation_date = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog_donation.save(form.last_donation_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input Phone -->
        <b-form-group
          id="input-group-phone"
          label="Nomor Handphone:"
          label-for="input-phone"
        >
          <b-form-input
            id="input-phone"
            v-model="form.phone"
            type="number"
            placeholder="Nomor Handphone"
          >
          </b-form-input>
          <small class="text-danger">{{ error.phone }}</small>
        </b-form-group>

        <!-- Input Gender -->
        <b-form-group
          id="input-group-gender"
          label="Jenis Kelamin:"
          label-for="input-gender"
        >
          <b-form-select
            id="input-gender"
            v-model="form.gender"
            :options="genders"
          ></b-form-select>
          <small class="text-danger">{{ error.gender }}</small>
        </b-form-group>

        <!-- Input Quantity -->
        <b-form-group
          id="input-group-quantity"
          label="Jumlah Kantong Darah:"
          label-for="input-quantity"
        >
          <b-form-input
            id="input-quantity"
            v-model="form.quantity"
            placeholder="Jumlah Kantong Darah"
          >
          </b-form-input>
          <small class="text-danger">{{ error.quantity }}</small>
        </b-form-group>

        <!-- Input Address -->
        <b-form-group
          id="input-group-address"
          label="Alamat:"
          label-for="input-address"
        >
          <b-form-textarea
            id="input-address"
            v-model="form.address"
            rows="3"
            placeholder="Alamat Pendonor"
          ></b-form-textarea>
          <small class="text-danger">{{ error.address }}</small>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
        >Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/donors/list')"
        >
          Batal
        </b-button>

      </b-form>

    </div>
  </v-app>
</template>

<script>
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {

  props: {
    form: Object,
    route: String
  },

  data() {
    return {
      error: {
        name: "",
        blood_type_id: null,
        age: null,
        birt_date: null,
        last_donation_date: "",
        phone: "",
        gender: null,
        quantity: null,
        email: "",
        id_card_number: null,
        address: "",
      },
      modal_birt_date: false,
      modal_last_donation_date: false,
      blood_types: [],
      genders: [
        { text: "Pilih Jenis Kelamin", value: null, disabled: true },
        { text: 'Laki-laki', value: 'Laki-laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
    }
  },

  methods: {

    async getBloodTypeOption() {
      let response = await module.setTreeSelect("blood-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.blood_types = response.data;
      }
    },

    setAge(evt) {

      var today = new Date();
      var birthDate = new Date(evt);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      this.form.age = age;
    },

    getAge(today) {

      var today = new Date();
      var birthDate = new Date(today);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      this.form.age = age;
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/donors')
      }
    }

  },

  mounted() {
    this.getBloodTypeOption()
    this.getAge(new Date().toISOString().substr(0, 10))
  }

}
</script>

<style>
</style>